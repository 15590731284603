
.Front {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;

  &.dark {
    background-color: rgba (0, 0, 0, 0.9);
  }
}

