@import '../../assets/scss/colors';

.portfolio {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  color: #fff;
  background-color: #000;
  z-index: 2;
  padding: 1rem;

  @media (min-width: 1200px) {
    padding: 2rem 0;
  }

  .cases {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 0.5rem;
    row-gap: 1rem;
    width: 100%;
    max-width: 1200px;
    height: auto;
    z-index: 3;

    @media (min-width: 1200px) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      column-gap: 1rem;
      row-gap: 1rem;

    }
  }

  .case {
    position: relative;
    width: 100%;
    height: 100%;
    color: #000;
    transform-origin: center;
    transition: all 0.35s ease-in-out;
    border: 0.5rem solid transparent;
    border-radius: 5px;

    &:hover {
      border-color: $red_600;
      cursor: pointer;

      .icon-square {
        background-color: $grey_300;
        color: $grey_900;
      }
    }

    .thumb {
      display: block;
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
    }

    .case-info {
      position: relative;
      display: block;
      position: absolute;
      top: -30px;
      right: -30px;
      // right: 0;
      height: 30px;
      width: 100%;
      z-index: 4;
    }

    .inner {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 30px;
      font-size: 0.75rem;
      font-weight: 700;
    }

    .icon-square {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: $grey_300;
      color: $grey_500;
      font-family: 'Material Symbols Sharp';
      font-size: 24px;
      aspect-ratio: 1/1;
      font-variation-settings:
        'FILL' 0,
        'wght' 200,
        'GRAD' 0,
        'opsz' 24,
      ;

      &:hover {
        cursor: pointer;
        background-color: $grey_300;
        color: #000;
      }
    }

    .case-pics {
      display: none; // height: 60vh;        
    }

    .detail {
      display: none;
    }



    .title {
      display: none;
    }

    &.open {
      position: absolute;
      width: 100%;
      height: auto;
      background-color: #000;
      animation: none;
      border: 0;
      z-index: 100;

      @media (min-width: 1200px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1200px;
        height: 820px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 50;
      }


      .thumb {
        display: none;
      }

      .inner {
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .title {
        display: inline-flex;
        font-family: var(--font-main);
        font-size: 0.875rem;
        z-index: 20;
        color: $grey_400;
      }

      img.thumb {
        display: none;
      }

      img.logo {
        display: flex;
        width: 120px;
        height: 12  0px;
        padding: 1rem;
        margin: 0 1rem 1rem 0;
        border: 1px solid $grey_400;
        border-radius: 50%;
      }

      .detail {
        flex: 0 1 600px;
        display: block;
        font-family: var(--font-main);
        font-size: 1rem;
        padding: 1rem;
        background-color: #fff;
        color: #000;

        @media (min-width: 1200px) {
          padding: 2rem;
          line-height: 1.6;
          font-size: 1.125rem;
          min-width: 400px;
        }
      }

      .case-pics {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        z-index: 101;
        background-color: #fff;

        @media (min-width: 1200px) {
          flex-direction: row;
          align-items: center;
          max-width: 1200px;
          margin: 0 auto;
        }

        .case-pic {
          flex: 1 1 auto;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-height: 800px;
          z-index: 10;

          img {
            display: block;
            }

        }

      }
    }

    .closed {
      z-index: 5;
    }

  }
}

@keyframes updown {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(25vh);
  }

  66% {
    transform: translateY(-25vh);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes downup {
  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(-25vh);
  }

  66% {
    transform: translateY(25vh);
  }

  100% {
    transform: translateY(0);
  }
}



@keyframes grow {
  0% {
    transform: scale(1);
  }

  80% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.case.paused {
  animation-play-state: paused;
}