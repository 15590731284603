$sky_200: #bae6fd;
$sky_300: #7dd3fc;
$sky_400: #38bdf8;
$sky_500: #0ea5e9;
$sky_600: #0284c7;
$sky_700: #0369a1;
$sky_800: #075985;
$sky_900: #0c4a6e;

$blue_200: #bfdbfe;
$blue_300: #93c5fd;
$blue_400: #60a5fa;
$blue_500: #3b82f6;
$blue_600: #2563eb;
$blue_700: #1d4ed8;
$blue_800: #1e40af;
$blue_900: #1e3a8a;

$indigo_200: #c7d2fe; 
$indigo_300: #a5b4fc;
$indigo_400: #818cf8;
$indigo_500: #6366f1;
$indigo_600: #4f46e5;
$indigo_700: #4338ca;
$indigo_800: #3730a3;
$indigo_900: #312e81;

$violet_200: #ddd6fe;
$violet_300: #c4b5fd;
$violet_400: #a78bfa;
$violet_500: #8b5cf6;
$violet_600: #7c3aed;
$violet_700: #6d28d9;
$violet_800: #5b21b6;
$violet_900: #4c1d95;

$grey_100: #f5f5f5;
$grey_200: #e5e5e5;
$grey_300: #d4d4d4;
$grey_400: #a3a3a3;
$grey_500: #737373;
$grey_600: #525252;
$grey_700: #404040;
$grey_800: #262626;
$grey_900: #171717;

$slate_100: #f1f5f9;
$slate_200: #e2e8f0;
$slate_300: #cbd5e1;
$slate_400: #94a3b8;
$slate_500: #64748b;
$slate_600: #475569;
$slate_700: #334155;
$slate_800: #1e293b;
$slate_900: #0f172a;


$red_100: #fff5f5;
$red_200: #fed7d7;
$red_300: #feb2b2;
$red_400: #fc8181;
$red_500: #f56565;
$red_600: #e53e3e;
$red_700: #c53030;
$red_800: #9b2c2c;
$red_900: #742a2a;





