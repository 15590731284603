@import '../../assets/scss/main';
$path: '../../assets/images/10--';


.front-cover {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  color: #000;
  z-index: 2;
  overflow: hidden;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  background-image: url('#{$path}400x600.jpg');

  @media (min-width: 401px) {
    background-image: url('#{$path}600x900.jpg');
  }

  @media (min-width: 601px) {
    background-image: url('#{$path}900x900.jpg');
    @media (orientation: portrait) {
      background-size: contain;
    }
    @media (orientation: landscape) {
      background-size: cover;
      background-position: center center;
    }

  }

  @media (min-width: 801px) {
    height: 100vh;
    max-height: 100vh;
    background-image: url('#{$path}900x900.jpg');

  }

  @media (min-width: 901px) {
    background-image: url('#{$path}1200x600.jpg');

    
    @media (orientation: portrait) {
      background-size: contain;
    }
    @media (orientation: landscape) {
      background-size: cover;
    }
  }

  @media (min-width: 1201px) {
    background-image: url('#{$path}1920x960.jpg');
  }

  @media (min-width: 1921px) {
    background-image: url('#{$path}2400x1200.jpg');
  }
  

  &.dark {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: min-content;
    padding: 1rem;
    background-color: #fff;

    @media (min-width: 1200px) {
      order: 2;
      padding: 2rem 0;
    }

    .logo {
      position: relative;   
      display: block;  
      width: 240px;
      padding: 2rem 3rem;
      height: auto;
      border-bottom: 1px solid #9ba0a8;
      z-index: 100;

      @media (min-width: 1200px) {
        width: 320px;
        padding: 3rem 4rem 3rem;

      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

    }

    h1 {
      position: relative;
      font-size: 2rem;
      line-height: 1.1;
      margin: 1rem 0 1.5rem;
      color: #000;
      text-align: center;

      @media (min-width: 1200px) {
        margin: 3rem auto 1rem auto;
        font-size: 2.5vw;
      }

    }

    h2.tagline {
      font-family: var(--ff-head);
      font-size: 0.875rem;
      line-height: 1.4;
      padding: 0 1rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      margin: 0 0 2rem;
      color: #555;

      @media (min-width: 1200px) {
        font-size: 1.25rem;
        letter-spacing: 3px;
        margin: 1rem 0 2rem 0;
      }
    }

    .lead {
      position: relative;
      font-size: 0.875rem;
      line-height: 1.6;
      margin: 0 0 1rem;
      padding: 0 1.5rem;
      color: #000;
      text-align: center;
      z-index: 100;

      @media (min-width: 1200px) {
        font-size: 1rem;
        max-width: 600px;
        margin: 0 auto 1rem auto;
      }
    }
  }

  @keyframes breathe {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.05);
    }

    100% {
      transform: scale(1);
    }
  }
}