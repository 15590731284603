@import "./assets/css/normalize.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp");
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');



:root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --ff-main: "Roboto Flex", sans-serif;
  --ff-head: "Playfair Display", serif;
  --ff-deco: "Rozha One", cursive;
}


*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body { /* except colors */
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 300;

  font-size: 16px;
  font-size: 100%;
  font-size: 1rem;


  -webkit-font-smoothing: antialiased;

  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XOPQ" 96,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  margin: 0;
}

.bg-black {
  background-color: #f5eded;
}

.bg-white {
  background-color: #fff;
}

.bg-gray {
  background-color: #ddd;
}

.bg-light-gray {
  background-color: #f0f0f0;
}

.bg-red {
  background-color: #b60d01;
}

