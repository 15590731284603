@import '../../assets/scss/main';

.nav-next {

  display: none;

  @media screen and (min-width: 1200px){
    display: block;
  }
  position: relative;
  width: 100%;
  z-index: 300;
}

.nextBtn {
  position: relative;
  display: inline-flex;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 1rem auto;
  border-radius: 50%;
  background-color: #000;
  border: 4px solid #fff;
  color: #fff;
  font-size: 1.5rem;
  z-index: 300;
  cursor: pointer;

  .material-symbols-sharp {
    color: #fff;
    display: inline-flex;
  }

  svg {
    color: #fff;
  }

  &:hover {
    background-color: $red_700;
  }
}

.prima .nav-next {
  order: 3;
  margin-top: 2vh;
}