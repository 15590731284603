@import './assets/scss/main';

body { // colors
  color: #000;
  background-color: #fff; 
}

.page {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  z-index: 1;

}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 2;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

.box-shadow-gentle {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);   
}

.hidden {
  display: none;
}

.button {
  display: block;
  font-family: 'Lexend', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background-color: black;
  padding: 1rem 2rem;
  cursor: pointer;
  border: 10px solid #fff;
  transition: all 0.35s;


  a {
    color: #fff;
    width: 100%;
    height: 100%;
    display: block;

  }

}


.blue-300 {
  color: #93c5fd;
}

.blue-400 {
  color: #60a5fa;
}

.blue-500 {
  color: #3b82f6;
}

.blue-600 {
  color: #2563eb;
}

.blue-700 {
  color: #1d4ed8;
}


.bg-blue-300 {
  background-color: #93c5fd;
  color: #000;
}

.bg-blue-400 {
  background-color: #60a5fa;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-700 {
  background-color: #1d4ed8;
  color: #fff;
}

.standalone {


  .nextBtn {
    bottom: 6rem;
  }
}

.boxed {
  padding: 1rem;
  width: 100%;

  @media (min-width: 1200px) {
    padding: 0;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.highlight {
  color: $red_700;
}
