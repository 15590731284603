@import '../../assets/scss/main';


.cta {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  z-index: 100;
  padding: 1.5rem 1rem;
  background-color: #ced2d6;

  @media (min-width: 1200px) {
    height: 22vh;
  }

  .box {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
      max-width: 1200px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .title {
    position: relative;
    color: #000;
    font-size: 2rem;
    text-align: center;
    margin: 1rem 0;
    font-weight: 400;

    .logo {
      position: relative;
      display: inline-flex;
      width: 100%;
      max-width: 240px;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      
      a {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      
        width: 100%;
        margin: 0 auto;

        img {
          position: relative;
          display: inline-flex;
          width: 100%;
          height: auto;
        }
      }
    }


    @media (min-width: 1200px) {
      width: 25%;
      flex: 1 1 auto;
      padding-right: 2rem;
    }

  }

  .face {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto;
    padding: 0 1rem;

    h3 {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin: 0 0 1rem;
      font-size: 1.25rem;
      color: #000;

      @media (min-width: 1200px) {
        font-size: 1.5rem;
      }

    }

    img {
      display: block;
      width: auto;
      height: 120px;
    }

  }

  .link {
    color: #000;
    flex: 1 1 auto;
    text-align: center;

    @media (min-width: 1200px) {
      width: calc(100% - 200px);
    }
  }

  .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>* {
      width: 100%;
      display: inline-flex;
      width: 210px;

      align-items: center;
      color: #000;
      font-size: 1rem;
      text-decoration: none;
      margin: 0.25rem 0;
      transition: all 0.3s ease;
    }

  }

  h4.person {
    font-size: 1.5rem;
    margin: 0.5rem 0;
    color: #000;
    text-align: center;
    font-weight: 400;
  }


  .nav {
    margin: 1rem 0;

    h4 {
      font-size: 1.25rem;
      margin: 0.5rem 0;
      color: #000;
      font-weight: 400;
    }
  }

  .icon-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    border-radius: 50%;
    background-color: #f0f0f0;
    font-family: 'Material Symbols Sharp';
    font-size: 24px;
    color: #333;
    aspect-ratio: 1/1;
    font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 24,
    ;

    @media (min-width: 1200px) {
      width: 52px;
      height: 52px;      
    }
    
  }

  a>.icon-circle:hover {
    &:hover {
      background-color: $red_500;
    }

  }
  

}